@use "@angular/material" as mat;
@import "~element-angular/theme/index.css";
@import "~@coreui/coreui/scss/coreui";
@import "~@coreui/chartjs/scss/coreui-chartjs";
@import "./partials/scrollbar";
@import "./partials/variables";
@import "./partials/fonts";
@import "./partials/pages/details.scss";
@import "./partials/pages/guide.scss";
@import "./partials/pages/form.scss";
@import "./partials/icons";
@import "./partials/new_icons";
@import "~@ng-select/ng-select/themes/default.theme.css";
$fasterr-frontend-primary: mat.define-palette(mat.$orange-palette);
$fasterr-frontend-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);
$fasterr-frontend-warn: mat.define-palette(mat.$red-palette);
$fasterr-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $fasterr-frontend-primary,
      accent: $fasterr-frontend-accent,
      warn: $fasterr-frontend-warn,
    ),
  )
);
@include mat.all-component-themes($fasterr-frontend-theme);
@include mat.core();

*,
html,
body {
  font-family: "SF Pro Display Medium", sans-serif;
}

html {
  font-size: 12px;
}

// default 1px = 0.0769rem;
body {
  font-size: 1rem !important;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) and (orientation: portrait) {
  .form-button {
    position: absolute;
    bottom: 0;
    right: 15px;
  }
}

::ng-deep .mat-calendar-body-cell {
  position: relative !important;
}

// @media only screen and (max-width:1000px)  and (orientation:portrait){
//     html {
//       height: 100vw;
//       -webkit-transform: rotate(90deg);
//       -moz-transform: rotate(90deg);
//       -o-transform: rotate(90deg);
//       -ms-transform: rotate(90deg);
//       transform: rotate(90deg);
//     }
//   }

html,
body {
  height: 100%;
  margin: 0;
  background-color: transparent;
}

div#mat-select-0-panel {
  max-height: calc(100% - 117px) !important;
}

div#mat-select-4-panel {
  max-height: calc(100% - 660px) !important;
}

body [class^="mat-"],
body [class*=" mat-"] {
  font-family: "SF Pro Display Medium", sans-serif;
}

input[type="checkbox"] {
  accent-color: var(--primary-color);
  transform: scale(1.2);
  margin-right: 6px;
}
.filterDot{
  width: 10px;
  height: 10px;
  background-color: var(--secondary-color) !important;
  top: -5px;
  right: -4px;
  border-radius: 50%;
  z-index: 9;
}

mat-pseudo-checkbox.mat-pseudo-checkbox.ng-star-inserted.mat-pseudo-checkbox-checked {
  background: var(--primary-color) !important;
}

mat-dialog-content.mat-dialog-content.mat-typography {
  max-height: calc(100vh - 260px);
}

body .material-icons {
  font-family: "Material Icons";
}

.custom-tab {
  .mat-tab-header {
    border-bottom: none !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: inherit;
}
mat-chip.mat-chip.mat-focus-indicator.mat-primary.mat-standard-chip.mat-chip-with-trailing-icon {
  background-color: var(--primary-color1);
  color: #fff;
  min-height: 26px;
}
mat-icon.mat-icon.notranslate.mat-chip-remove.mat-chip-trailing-icon.material-icons.mat-icon-no-color {
  color: #fff;
}

.dropdown-item:active {
  background-color: var(--primary-color) !important;
}

.header {
  padding: 0rem 0.5rem;
}

.custom-input-header {
  .mat-form-field-appearance-fill .mat-form-field-underline {
    height: 0px !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    // padding: 14px 0px 0px 0px;
    padding: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-prefix .mat-icon,
  .mat-form-field-appearance-fill .mat-form-field-suffix .mat-icon {
    width: auto;
    height: auto;
    font-size: 160%;
    margin-right: 5px;
    color: var(--slate-gray);
  }

  .mdi-icon {
    position: absolute;
    left: 0.5em !important;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent;
    height: 0;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: transparent;
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    height: 0px;
  }

  input.mat-input-element {
    margin-top: -0.0625em;
    margin: 0px 0px 8px 0px;
  }
}

// .cdk-overlay-pane {
//   position: absolute !important;
//   top: 0;
//   right: 15px;
// }

.sidebar-brand {
  background: var(--athens-gray);
  justify-content: left;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 14px;
}

.SPLogo {
  padding-bottom: 10px;
}

.ClientLogo {
  padding-bottom: 23px;
  padding-top: 12px;
}

svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  width: 18px;
  height: auto;
}

mat-datepicker-toggle.mat-datepicker-toggle {
  margin-right: -5px;
}

.sidebar {
  position: sticky;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 240px;
  z-index: 996;
  transition: all 0.3s;
  padding: 0;
  overflow-y: none;
  scrollbar-width: thin;
  scrollbar-color: var(--casper) transparent;
  box-shadow: none;
  background-color: var(--athens-gray);
  --cui-sidebar-occupy-start: 14.5rem !important;
}

.sidebarHidden {
  width: 67px !important;
  z-index: 0;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--white-primary);
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--casper);
}

// @media (min-width: 1200px) {

//     #main,
//     #footer {
//         margin-left: 300px;
//     }
// }

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--nepal);
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle::before {
  content: "\f28a";
}

.bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.mr-9 {
  margin-right: 9px;
}

.mr-12 {
  margin-right: 12px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-19 {
  margin-top: 19px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mt-82 {
  margin-top: 82px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mt-20 {
  margin-top: 20px;
}

.custom-checkbox-asn-form {
  .mat-checkbox-layout .mat-checkbox-label {
    margin-right: 30px;
    margin-left: 5px;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--science-blue) !important;
    border-radius: 40px;
    border: 1px solid var(--science-blue) !important;
  }

  .mat-checkbox-frame {
    border-color: var(--science-blue) !important;
    border-radius: 10px;
  }
}

.backdrop {
  background: transparent !important;
}

div#mat-menu-panel-0 {
  min-width: 125px;
}

div#cdk-overlay-0 {
  margin-top: 5px;
}

.mat-menu-content {
  max-height: 200px;
}

.form-date-icon-po {
  ::ng-deep .mat-icon-button {
    width: 40px !important;
  }
}

.mat-form-field.mat-focused .mat-form-field-ripple{
  background-color: var(--primary-color) !important;
}

// }
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #333333;
}

.mat-progress-bar-buffer.mat-progress-bar-element {
  background-color: var(--zircon);
}

.mat-progress-bar-fill::after {
  background-color: var(--primary-color);
}

.mat-radio-outer-circle {
  height: 15px !important;
  top: 3px !important;
  width: 15px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #799496;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #799496;
}

.mat-ripple {
  border-radius: 50px;
  // padding: 0 !important;
  margin-right: 5px;
}

.mat-radio-inner-circle {
  height: 15px !important;
  top: 3px !important;
  width: 15px !important;
}

.text-red {
  color: var(--red-primary);
}

.text-green {
  color: var(--japanese-laurel);
}

.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}

@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}

.custom-btn {
  background-color: var(--primary-color) !important;
  color: var(--white-primary);
  font-size: 16px;
  border: 1px solid var(--primary-color) !important;
}

.custom-btn:hover {
  color: var(--white-primary) !important;
}

.f-s-36 {
  font-size: 36px !important;
}

.f-s-30 {
  font-size: 30px !important;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-18 {
  font-size: 18px;
}

.welcome {
  color: var(--shuttle-gray);
}

.forgot {
  color: var(--azure-radiance) !important;
  text-decoration: none;
}

.logo-shtiks {
  position: absolute;
  top: 71px;
  right: 0;
  left: 70px;
  bottom: 0;
}

.rights {
  position: absolute;
  // top: 0px;
  right: 0;
  left: 70px;
  // bottom: 47px;
  bottom: calc(100% - 94%);
  color: var(--white-primary);
  opacity: 0.7;
}

.z-index-9 {
  z-index: 9;
}

screen-title {
  > .titleWithTabPills {
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid var(--color-global-grid-border);
    background: white;

    .wms-tab-head {
      border: none !important;
      float: right;
    }
  }

  + div.tab-content .tab-pane {
    margin-top: 10px;
    padding: 0px;
  }
}

.grid-actions {
  display: flex;
  gap: 0.6rem;
  margin-right: 0.6rem;

  button {
    font-size: 1.4rem !important;
    border: 1px solid #dfe1e6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    width: 32px;
    height: 32px;
    border-radius: 0.3rem;
    background: #f5f6f7;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.form-height {
  height: calc(100vh - 155px);
}

.container-fluid {
  label.bread-crumb {
    margin-bottom: 0px;
  }

  h3.form-heading {
    margin-bottom: 5px;
  }
}

screen-title[fornavbar="true"] + .tab-content > .tab-pane {
  height: auto !important;
}

.custom-option {
  .mat-option {
    line-height: 10px !important;
    padding: 0px 5px !important;
    margin: 10px 0px;
  }
}

.mat-option {
  height: 30px !important;
  font-size: 1rem !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none;
}

::ng-deep .mat-tab-label {
  opacity: 1 !important;
}

app-dashboard.ng-star-inserted {
  position: relative;
  z-index: 0;
}

.text-rap {
  color: var(--fiord);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
}

/**--------------****GRID UI****--------------------**/
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--primary-color) !important;
}

::ng-deep .mat-tab-body-wrapper {
  margin-top: 35px !important;
}

::ng-deep {
  @media only screen and (min-width: 320px) and (max-width: 1024px) {
    .mat-tab-body-content {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  @media only screen and (min-width: 1025px) {
    .mat-tab-body-content {
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }
  }
}

::ng-deep .mat-tab-label {
  text-align: left !important;
  justify-content: left !important;
  min-width: 100px !important;
  padding: 0px 8px 0px 0px !important;
}

.multi-select-dropdown {
  position: relative;
  cursor: pointer;
}

.multi-select-dropdown:hover + .multi-select-dropdown-menu {
  display: block;
}

.multi-select-dropdown-menu {
  display: none;
  position: fixed;
  width: 200px;
  min-height: 100px;
  height: auto;
  background-color: #f3f3f3;
  padding: 8px;
  border-radius: 5px;
  font-size: 10px;
  z-index: 9999;
}
.mat-chip-list-wrapper {
  flex-wrap: nowrap !important;
  overflow-x: clip;
}
.custom-chip {
  margin: 4px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 32px;
  font-size: 14px;
  font-weight: 500;
  height: 1px;
}
.mat-chip-list-wrapper input.mat-input-element,
.mat-chip-list-wrapper .mat-standard-chip {
  white-space: nowrap !important;
}

::ng-deep .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none !important;
}

.mat-tab-label-active .mat-tab-label-content {
  font-weight: bold;
  text-align: left !important;
  justify-content: left !important;
  padding: 0px 20px 0px 0px !important;
  border-radius: 100px !important;
  padding: 6px 18px !important;
  font-size: 14px !important;
  color: var(--fiord) !important;
  background-color: var(--dim-pink) !important;
}

.add {
  margin-right: 16px;
  margin-bottom: 3px;
}

::ng-deep html:not([dir="rtl"]) .text-center {
  text-align: left !important;
}

::ng-deep .mat-stroked-button:not(.mat-button-disabled) {
  border-color: var(--primary-color);
}

.w-15 {
  width: 15%;
}

.custom-btn-width {
  width: 118px;
}

.cursorshow {
  cursor: pointer;
}

.cursorhide {
  cursor: default;
}

/**--------------****GRID UI - END****--------------------**/
::ng-deep .mat-datepicker-popup {
  margin-top: 0rem !important;
  max-height: 100% !important;
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl("padding-right", var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

//**** mat-flat-buttom ****//
.mat-flat-button.btn-main,
.mat-flat-button.btn-outlined {
  border: 1px solid var(--primary-color) !important;
  line-height: 2 !important;
  padding: 0.1rem 2rem;
  font-family: "SF Pro Display" !important;
  font-size: 1rem !important;

  .mat-icon {
    margin: 0 8px 0 0 !important;
    font-size: 1.7rem;
    width: 1.7rem;
    display: inline-flex;
    align-items: center;
  }
}

.mat-flat-button.btn-outlined {
  background: transparent !important;
  color: #231F20 !important;
  border-color: #231F20 !important;
}

.mat-flat-button.btn-main {
  background: #1C3343 !important;
  color: #fff !important;
}

.btn-badgeSuccess {
  background: #0fce39;
  color: #fff;
  line-height: 2 !important;
  padding: 0.1rem 2rem;
  font-family: "SF Pro Display" !important;
  font-size: 1rem !important;
}

//==== Mat select css start //
.ng-placeholder,
input::placeholder {
  font-family: "SF Pro Display Medium", "Helvetica Neue", sans-serif;
  color: #949494 !important;
  padding-top: 4px !important;
}

.ng-select .ng-select-container {
  height: 39px !important;
  border: 1px solid rgb(224 224 224);
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 120px;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 5px !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: 0 !important;
}

.ng-select span.ng-arrow-wrapper .ng-arrow::after {
  content: "\f282";
  display: inline-block;
  font-family: bootstrap-icons !important;
  -webkit-font-smoothing: antialiased;
  transform: translateX(-5px);
  transition: 0.4s ease-out;
  font-weight: bold;
}

.ng-select-opened span.ng-arrow-wrapper .ng-arrow::after {
  transform: rotate(180deg) translateX(5px);
}

.ng-dropdown-panel-items.scroll-host {
  padding: 0.5rem 0.8rem !important;
}

.ng-select.select-box {
  width: 7rem !important;
}

.ng-select.select-box .ng-select-container {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0 !important;
  height: 24px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #949494;
  box-shadow: none;
}

.ng-select.input-error .ng-select-container {
  border: 1px solid #ff3e3e;
}

.ng-select.input-error.line-select .ng-select-container {
  border-bottom-color: #ff3e3e;
}

//==== Mat select css ends //

.cdk-global-scrollblock {
  position: static;
  overflow: hidden !important;
}

.mb-80 {
  margin-bottom: 80px;
}

.px-16 {
  padding-right: 1.2304rem !important;
  padding-left: 1.2304rem !important;
}

.el-textarea textarea {
  padding: 5px 10px !important;
  font-size: 1rem !important;
  font-weight: 100;
}

.relative {
  position: relative;
}

.btn-main.mat-button-disabled[disabled="true"] {
  opacity: 0.5;
}

.attachment-close-icon {
  display: inline-block;
  font-size: 9px;
  font-weight: 600;
  line-height: 1;
  color: #999;
  cursor: pointer;
  position: absolute;
  z-index: 20;
  right: 0rem;
  top: 0.6rem;
}

.mat-icon.attachment {
  position: relative;
  top: 0.3rem;
  left: 0.8rem;
}

.attachment-close-icon:hover {
  color: #d0021b !important;
}

.mand-staric {
  font-size: 15px;
  top: 0px;
  left: -2px;
}

.ng-select .ng-spinner-loader {
  border-left: 2px solid var(--primary-color) !important;
}

.mat-autocomplete-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid var(--primary-color);
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-animation: spin 0.8s linear infinite;
  /* Safari */
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// .material-icons {
//     font-family: 'Material Icons';
//     font-weight: normal;
//     font-style: normal;
//     font-size: 24px;
//     line-height: 1;
//     letter-spacing: normal;
//     text-transform: none;
//     display: inline-block;
//     white-space: nowrap;
//     word-wrap: normal;
//     direction: ltr;
//     --webkit-font-feature-settings: 'liga';
//     --webkit-font-smoothing: antialiased;
//   }
@media screen and (max-width: 1000px) {
  .sp-list-footer {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
  }

  .SPcontentSectionSizeOnMenuHide .sp-list-footer {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 180px);
  }

  .client-list-footer {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 100px);
  }
}

// .ng-select .ng-select-container {
//   height: auto !important;
// }

.ng-select.ng-select-opened .ng-select-container {
  height: auto !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--selago) !important;
  color: var(--fiord) !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid var(--selago) !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: var(--dim-pink) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--selago) !important;
  color: var(--fiord) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--selago) !important;
  color: var(--fiord) !important;
}

.pack-button {
  background: var(--primary-color);
  padding: 7px 13px 7px 13px;
  border: none;
  border-radius: 4px;
  height: 40px;
  margin-left: 15px;
}

.mat-calendar-body-selected{
  background-color: var(--secondary-color) !important;
}

.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color: var(--secondary-color) !important;
}

.mat-datepicker-content{
  display: flex !important;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.mat-datepicker-content .time-container{
  position: unset !important;
}

.mat-datepicker-content .mat-calendar{
  width: 250px !important;
}

.mat-calendar-body-cell-content.mat-focus-indicator{
  height: 27px !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color: var(--secondary-color) !important;
}

.mat-calendar-body-cell{
  position: relative !important;
}

// .mat-calendar-body-cell-content{
//   font-size: 10px !important;
// }

.mat-datepicker-content{
  .mat-calendar{
    height: auto !important;
  }

  .actions > .mat-stroked-button{
    padding: 0 12px !important;
    min-width: 50px !important;
    width: 50px !important;
  }
}

.ngx-mat-timepicker-meridian{
  .mat-stroked-button{
    width: 30px !important;
    height: 30px !important;
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  .mat-button-wrapper{
    font-size: 12px !important;
  }
}
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
//     background-color: var(--dim-pink) !important;
// }

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
//     background-color: var(--dim-pink) !important;
// }
@media only screen and (max-width: 768px) {
  body {
    overflow-y: scroll !important;
  }

  .resp-deatil-card-height {
    height: calc(100vh - 230px) !important;
  }
}

@media only screen and (max-width: 768px) {
  .form-height {
    height: calc(100vh - 230px);
  }
}

@media screen and (max-width: 600px) {
  .client-list-footer {
    width: calc(100% - 10%);
  }

  .mat-datepicker-content .mat-calendar {
    width: 252px !important;
    height: 280px !important;
  }
}

.w-30 {
  width: 30%;
}

.ngx-foreground-spinner{
  top: 45% !important;
}
#priorty-set-dialog {
  padding: 0px !important;
  overflow: hidden;
}
#priorty-assign-dialog {
  padding: 0px !important;
  overflow: hidden;
  border-radius: 20px;
}
.priority-tasks{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: var(--emerald);
  border-radius: 100px;
}

.highlight a{
  text-decoration: none !important;
  cursor: pointer;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none !important;
}
.custom-overlay-class {
  .mat-menu-panel[role="menu"] {
    min-width: 170px !important;
  }
  .mat-menu-item {
    max-height: fit-content !important;
    height: 32px !important;
    line-height: 0px !important;
    font-size: 13px !important;
  }
  .mat-menu-content{
    padding: 5px !important;
  }
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  background-color:none;
  border: none;

  .wrap-text{
    max-width: fit-content;
  }
}

.app-guide-dialog .mat-dialog-container {
  padding: 0 !important;
}

// Panel class added for the job automation schedule popup
.schedule-dialog {
  .mat-dialog-container {
    padding: 24px!important;
  }}
// Panel class added for the po asn confirmation popup
.asn-confirmation-dialog {
  .mat-dialog-container {
  padding: 18px 0 !important;
  }
}
// Panel class added for the integration popup
.configure-account-dialog {
  .mat-dialog-container {
    padding: 24px!important;
  }}
.warning-dialog {
  .mat-dialog-container {
    padding: 24px!important;
  }
}
