.joyride-step__container {
    width: 400px ;
    border-radius: 12px ;
    border-radius: 10px;
    box-shadow: none !important;
  }
  .joyride-step__close{
    display: none;
  }
  .joyride-step__counter{
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #000000 !important;
  }
  .joyride-step__body {
    text-align: left;
    padding:10px 8px 28px 8px !important;
}
.joyride-step__title{
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #000000 !important;
}

.joyride-button{
  background: var(--primary-color) !important;
  border: none;
  border-radius: 5px !important;
  padding: 2px 14px !important;
  color: #ffffff !important;
}

.joyride-step__arrow{
  left: 375px !important;
  // top: 148px !important;
  .joyride-arrow__bottom {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 9px solid #ffffff;
  }
  &::after {
    content: ''; // Required for pseudo-elements
    position: absolute; // Position the circle relative to its parent
    width: 30px; // Adjust the width of the circle as needed
    height: 30px; // Adjust the height of the circle as needed
    background-image: url('../../assets//images//guide-logo.png'); // Replace with path to your circle image
    background-size: contain; // Ensure the circle image fits within the dimensions
    top: 100%; // Position the circ8e below the arrow
    left: 50%; // Position the circle horizontally centered
    transform: translateX(-50%); // Center the circle horizontally
    margin-top: 3px;
  }
}
.tour-next-button {
 
  border-radius: 4.8px !important;
 
}

