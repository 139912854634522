@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/shtiks-icons.woff') format('woff'),
        url('../assets/fonts/receiving.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/wizefulfill.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"],
[class*=" icon-"], [class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e919";
  color: inherit;
}

.icon-delete:before {
  content: "\e91a";
  color: inherit;
}

.icon-pen:before {
  content: "\e91b";
  color: inherit;
}

.icon-img:before {
  content: "\e91c";
  color: inherit;
}

.icon-pdf:before {
  content: "\e91d";
  color: inherit;
}

.icon-export:before {
  content: "\e901";
  color: inherit;
}

.icon-import:before {
  content: "\e900";
  color: inherit;
}

.icon-wms:before {
  content: "\e917";
  color: inherit;
}

.icon-logistics:before {
  content: "\e918";
  color: inherit;
}

.icon-history:before {
  content: "\e915";
}

.icon-comment:before {
  content: "\e916";
}

.icon-inbound:before {
  content: "\e902";
  color: inherit;
}

.icon-recieving:before {
  content: "\e903";
  color: inherit;
}

.icon-inventory:before {
  content: "\e904";
  color: inherit;
}

.icon-outbound:before {
  content: "\e905";
  color: inherit;
}

.icon-notes:before {
  content: "\e906";
  color: inherit;
}

.icon-dashboard:before {
  content: "\e90b";
  color: inherit;
}

.icon-truck-management:before {
  content: "\e90c";
  color: inherit;
}

.icon-ticket-management:before {
  content: "\e90d";
  color: inherit;
}

.icon-settings:before {
  content: "\e90e";
  color: inherit;
}

.icon-search:before {
  content: "\e90f";
  color: inherit;
}

.icon-notification:before {
  content: "\e910";
  color: inherit;
}

.icon-filter:before {
  content: "\e911";
  color: inherit;
}

.icon-attachment:before {
  content: "\e912";
  color: inherit;
}

.icon-arrow-back:before {
  content: "\e913";
}

.icon-fulfillment:before {
  content: "\e914";
  color: inherit;
}

.icon-barcode:before {
  content: "\e907";
  color: #344563;
}
.icon-Group-1:before {
  content: "\e900";
  color: #0052cc;
}

.icon-receiving:before {
  content: "\e900";
  color: inherit;
}

.ic-file-check:before {
  content: "\e920";
}

.ic-chevron-left:before {
  content: "\e921";
}
.ic-chevron-right:before {
  content: "\e922";
}
.ic-setting-gear:before {
  content: "\e923";
}
.ic-settings-amplifier:before {
  content: "\e924";
}