@font-face {
   font-family: 'SF Pro Display';
   src: url(../assets/fonts/SFPRODISPLAYREGULAR.woff) format("woff");
   font-weight: 400;
}
@font-face {
   font-family: 'SF Pro Display Medium';
   src: url(../assets/fonts/SFPRODISPLAYMEDIUM.woff) format("woff");
   font-weight: 500;
}
@font-face {
   font-family: 'SF Pro Display Semibold';
   src: url(../assets/fonts/SFPRODISPLAYSEMIBOLD.woff) format("woff");
   font-weight: 600;
}
@font-face {
   font-family: 'SF Pro Display Bold';
   src: url(../assets/fonts/SFPRODISPLAYBOLD.woff) format("woff");
   font-weight: 700;
}

@font-face {
   font-family: "bootstrap-icons";
   src: 
       url(../assets/fonts/bootstrap-icons.woff2?30af91bf14e37666a085fb8a161ff36d) format("woff2"),
       url(../assets/fonts/bootstrap-icons.woff?30af91bf14e37666a085fb8a161ff36d) format("woff");
}