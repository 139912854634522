.custom-height {
  // height: calc(100vh - 500px);
  height: calc(100vh - 470px);
  // overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-height-1 {
  height: calc(100vh - 270px);
  overflow-y: auto;
  overflow-x: hidden;
}

.el-table tr th {
  background-color: var(--white-primary);
  background-color: var(--athens-gray-1);
}

table tr th td :first-child {
  padding-left: 7px !important;
  // width: 30px;
}

.mat-table thead,
.mat-table tfoot,
.mat-table-sticky {
  background: var(--alabaster);
  border: none !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

.mat-header-cell {
  color: var(--oxford-blue);
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 5px 7px !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 90%;
}

.mat-table .mat-table tbody,
.mat-table tfoot,
mat-row,
mat-footer-row,
[mat-row],
[mat-footer-row],
.mat-table-sticky {
  border: 1px solid var(--gallery);
}

.custom-input {
  ::ng-deep .mat-form-field {
    // width: 50%;
    width: 100% !important;
    max-width: 100px;
  }
}

.custom-input1 {
  ::ng-deep .mat-form-field {
    width: 80%;
  }
}

.custom-select {
  ::ng-deep .el-input__inner {
    border-radius: 0px;
    border-top: 0px solid var(--mischka);
    border-right: 0px solid var(--mischka);
    border-bottom: 1px solid var(--fiord);
    border-left: 0px solid var(--mischka);
    color: var(--fiord);
    padding: 0px;
    width: 100%;
  }
}

.custom-date-picker {
  ::ng-deep .el-input__inner {
    border-radius: 0px;
    border-top: 0px solid var(--mischka);
    border-right: 0px solid var(--mischka);
    border-bottom: 1px solid var(--fiord);
    border-left: 0px solid var(--mischka);
    color: var(--fiord);
    padding: 0px;
    width: 100%;
  }
}

.pr-10 {
  padding-right: 10px;
}

.pr-0 {
  padding-right: 0px;
}

.el-select .el-input .el-select__caret {
  color: var(--fiord) !important;
}

.el-input__prefix,
.el-input__suffix {
  color: var(--fiord) !important;
}

.req-custom {
  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: var(--mischka) !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
    border: 1px solid var(--mischka);
    border-radius: 4px 4px 0 0;
    padding: 0 0.75em 0 0.75em;
    align-items: center;
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--fiord);
  }
}

.req-custom-table {
  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: var(--mischka) !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
    border: none;
    border-radius: 4px 4px 0 0;
    padding: 0 0.75em 0 0.75em;
    align-items: center;
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--fiord);
  }


  .mat-form-field-infix {
    width: 100px;
  }
}

.uom {
  ::ng-deep .el-select {
    width: max-content;
    margin-bottom: 18px;
  }
}

.w-max-content {
  width: max-content;
}

.t-shirt {
  color: var(--fiord);
}

.pl-5 {
  padding-left: 5px;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.font-weight-bold {
  font-weight: bold;
}

.w-15 {
  width: 15%;
}

.home-pr {
  color: var(--slate-gray);
}

.p-request {
  color: var(--nile-blue);
  font-size: 18px;
}

.mr-8 {
  margin-right: 8px;
}

.w-50 {
  width: 50%;
}

.mt-19 {
  margin-top: 19px;
}

.mr-21 {
  margin-right: 21px;
}

.mt-15 {
  margin-top: 15px;
}

.d-grid {
  display: grid;
}

::ng-deep .mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--primary-color);
}

::ng-deep .el-button--primary {
  background-color: var(--primary-color);
}

::ng-deep .el-button--primary:hover {
  background: var(--primary-color-1);
  border-color: var(--primary-color);
}

.add {
  margin-right: 16px;
  margin-bottom: 3px;
}

.hide-autocomplete {
  display: none;
}

.input-height {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 4px 0px 14px 0px;
  }

}
.multi-height{
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 4px 0px 13px 0px;
  }
}

// .custom-tab-asn {
  ::ng-deep .mat-tab-header {
    top: 0;
    z-index: 1000;
    position: sticky;
    // background-color: var(--white-primary);
  }

  ::ng-deep .mat-tab-body-content {
    margin-top: 20px;
  }

  ::ng-deep {
    @media only screen and (min-width: 320px) and (max-width: 1024px) {
      .mat-tab-body-content {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    @media only screen and (min-width: 1025px) {
      .mat-tab-body-content {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
      }
    }
  }

  ::ng-deep .mat-tab-body-wrapper {
    margin-top: 10px !important;
  }

  ::ng-deep .mat-tab-body {
    overflow-y: hidden;
  }

  ::ng-deep .mat-tab-body.mat-tab-body-active {
    overflow-y: hidden;
  }

  ::ng-deep .mat-tab-label .mat-tab-label-content {
    background-color: transparent !important;
    // padding: 6px 18px;
    padding: 0;
    border-radius: 100px;
    color: var(--fiord);
    font-size: 14px;
  }

  ::ng-deep .mat-tab-label {
    text-align: left !important;
    justify-content: left !important;
    
    padding: 0px !important;
    // padding: 0px 8px 0px 0px !important;
  }

  ::ng-deep .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    display: block !important;
  }

  ::ng-deep .mat-tab-label-active .mat-tab-label-content {
    font-weight: bold;
    text-align: left !important;
    justify-content: left !important;
    // padding: 0px 20px 0px 0px !important;
    border-radius: 100px !important;
    // padding: 6px 18px !important;
    font-size: 14px !important;
    color: var(--fiord) !important;
    background-color: var(--dim-pink) !important;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-color) !important;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary[_ngcontent-khe-c273] .mat-ink-bar[_ngcontent-khe-c273] {
    display: block !important;
  }
// }3

.select-position {
  ::ng-deep .el-select-dropdown {
    position: initial !important;
    max-width: 100%;
    min-width: 100%;
  }
}

::ng-deep .native-input {
  -webkit-appearance: none;
  background-color: var(--white-primary);
  border-radius: 4px;
  border: 1px solid var(--mischka);
  box-sizing: border-box;
  color: var(--shuttle-gray-1);
  // display: inline-block;
  display: block;
  font-size: inherit;
  height: 43px;
  line-height: 1;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
  // margin-top: 7px;
  margin-top: -63px;
  opacity: 0;
}

mat-form-field {
  &.input-error {

    // to change border color
    .mat-form-field-outline {
      color: red !important;
      border-width: 1px !important;
      & div, & div:hover, & div:focus {
        border-width: 1px !important;
      }
    }

    // to change label color
    .mat-form-field-label {
      //  color: red !important;
    }

    &:hover .mat-form-field-outline {
      color: red !important;
    }
  }
}

// TODO remove later
el-select {
  &.input-error {

    // to change border color
    .el-input__inner {
      border-color: red !important;
    }
  }
}

el-input {
  &.input-error {

    // to change border color
    .el-textarea__inner {
      border-color: red !important;
    }
  }
}

// Added by Owais
.mat-form-field-appearance-legacy.input-error .mat-form-field-underline {
  background-color: red !important;
}

.el-select-dropdown {
  max-width: 100% !important;
  min-width: 100% !important;
}

.el-select-dropdown__list {
  max-height: 120px !important;
  overflow-y: scroll !important;
}

::ng-deep .mat-autocomplete-panel.mat-autocomplete-visible {
  margin-top: 0rem !important;
  max-height: 120px !important;
}

::ng-deep .mat-datepicker-popup {

  margin-top: 0rem !important;
  max-height: 100% !important;
}

.native-input {
  -webkit-appearance: none;
  background-color: var(--white-primary);
  border-radius: 4px;
  border: 1px solid var(--mischka);
  box-sizing: border-box;
  color: var(--shuttle-gray-1);
  // display: inline-block;
  display: block;
  font-size: inherit;
  height: 43px;
  line-height: 1;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
  // margin-top: 7px;
  margin-top: -63px;
  opacity: 0;
}


.el-select-dropdown {
  max-width: 100% !important;
  min-width: 100% !important;
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  margin-top: 0rem !important;
  max-height: 120px !important;
}

.mat-datepicker-popup {
  margin-top: 0rem !important;
  max-height: 100% !important;
}

.t-shirt {
  color: var(--fiord);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
}

.sku {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.pro_img {
  border: 2px solid;
  border-radius: 20%;
  border-color: #f4f5f7;  border-radius: 20%;
}

:Host ::ng-deep .mat-stroked-button:not(.mat-button-disabled),
:Host ::ng-deep button.mat-stroked-button:not(.mat-button-disabled),
:Host ::ng-deep button.mat-stroked-button.mat-button-base {
  border-color: var(--primary-color) !important;
}

::ng-deep .custom-panel {
  position: relative;
  top: 0.4rem;
  max-width: 1rem !important;
}

.mat-form-field-flex .mat-form-field-outline,
.mat-form-field-flex:hover .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12) !important;
}

.el-select-dropdown__list {
  max-height: 120px !important;
  overflow-y: scroll !important;
}

.select-position {
  ::ng-deep .el-select-dropdown {
    position: initial !important;
    max-width: 100%;
    min-width: 100%;
  }
}

::ng-deep .mat-select-arrow {
  opacity: 0;
}

::ng-deep .mat-form-field ::ng-deep {
  .mat-select-arrow-wrapper {
    transition: 0.5s;
  }
}

::ng-deep .mat-form-field.active ::ng-deep {
  .mat-select-arrow-wrapper {
    transform: rotate(180deg) !important;
  }
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:var(--primary-color) !important;
}
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle{
  background-color:var(--primary-color) !important;
}