// NOTE: This file contains all the generic classes for details page.
.custom-height {
   height: calc(100vh - 500px);
   // overflow-x: hidden;
   // overflow-y: auto;
   overflow: auto;
}

::ng-deep .el-table tr th {
   background-color: var(--white-primary);
   background-color: var(--athens-gray-1);
}

table tr th td :first-child {
   padding-left: 7px !important;
   // width: 30px;
}

::ng-deep .mat-table thead,
.mat-table tfoot,
.mat-table-sticky {
   background: var(--alabaster);
   border: none !important;
}

::ng-deep th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
   padding-left: 10px !important;
}

::ng-deep .mat-header-cell {
   color: var(--oxford-blue);
}

::ng-deep th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
   padding: 5px 7px !important;
}

::ng-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
   width: 90%;
}

.mat-table .mat-table tbody,
.mat-table tfoot,
mat-row,
mat-footer-row,
[mat-row],
[mat-footer-row],
.mat-table-sticky {
   border: 1px solid var(--gallery);
}

.custom-input {

   //  ::ng-deep .el-input__inner {
   //     border-radius: 0px;
   //     border-top: 0px solid var(--mischka);
   //     border-right: 0px solid var(--mischka);
   //     border-bottom: 1px solid var(--fiord);
   //     border-left: 0px solid var(--mischka);
   //     color: var(--fiord);
   //     text-align: center;
   //     padding: 0px;
   //     width: 30%;
   // }
   ::ng-deep .mat-form-field {
      width: 50%;
   }
}

.custom-input1 {

   //  ::ng-deep .el-input__inner {
   //     border-radius: 0px;
   //     border-top: 0px solid var(--mischka);
   //     border-right: 0px solid var(--mischka);
   //     border-bottom: 1px solid var(--fiord);
   //     border-left: 0px solid var(--mischka);
   //     color: var(--fiord);
   //     padding: 0px;
   //     width: 100%;
   // }
   ::ng-deep .mat-form-field {
      width: 80%;
   }
}

.custom-select {
   ::ng-deep .el-input__inner {
      border-radius: 0px;
      border-top: 0px solid var(--mischka);
      border-right: 0px solid var(--mischka);
      border-bottom: 1px solid var(--fiord);
      border-left: 0px solid var(--mischka);
      color: var(--fiord);
      padding: 0px;
      width: 100%;
   }
}

.custom-date-picker {
   ::ng-deep .el-input__inner {
      border-radius: 0px;
      border-top: 0px solid var(--mischka);
      border-right: 0px solid var(--mischka);
      border-bottom: 1px solid var(--fiord);
      border-left: 0px solid var(--mischka);
      color: var(--fiord);
      padding: 0px;
      width: 100%;
   }
}

// ::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
// .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//     background-color: var($custom-primary);
// }
.pr-10 {
   padding-right: 10px;
}

::ng-deep .mat-icon-button {
   width: 20px !important;
}

.pr-0 {
   padding-right: 0px;
}

::ng-deep .el-select .el-input .el-select__caret {
   color: var(--fiord) !important;
}

::ng-deep .el-input__prefix,
.el-input__suffix {
   color: var(--fiord) !important;
}

.req-custom {
   ::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: var(--mischka) !important;
   }

   ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent;
      border: 1px solid var(--mischka);
      border-radius: 4px 4px 0 0;
      padding: 0 0.75em 0 0.75em;
      align-items: center;
   }

   ::ng-deep .mat-datepicker-toggle,
   .mat-datepicker-content .mat-calendar-next-button,
   .mat-datepicker-content .mat-calendar-previous-button {
      color: var(--fiord);
   }
}

.req-custom-table {
   ::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
      background-color: var(--mischka) !important;
   }

   ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: transparent;
      border: none;
      border-radius: 4px 4px 0 0;
      padding: 0 0.75em 0 0.75em;
      align-items: center;
   }

   ::ng-deep .mat-datepicker-toggle,
   .mat-datepicker-content .mat-calendar-next-button,
   .mat-datepicker-content .mat-calendar-previous-button {
      color: var(--fiord);
   }

   ::ng-deep .mat-form-field-infix {
      width: 100px;
   }
}

.uom {
   ::ng-deep .el-select {
      width: max-content;
      margin-bottom: 18px;
   }
}

.w-max-content {
   width: max-content;
}

.t-shirt {
   color: var(--fiord);
}

.pl-5 {
   padding-left: 5px;
}

.mr-4 {
   margin-right: 1.5rem;
}

.font-weight-bold {
   font-weight: bold;
}

.w-15 {
   width: 15%;
}

.home-pr {
   color: var(--slate-gray);
}

.p-request {
   font-family: "SF Pro Display", sans-serif;
   color: #000000 !important;
   font-size: 15px !important;
}

.mr-8 {
   margin-right: 8px;
}

.w-50 {
   width: 50%;
}

.mt-19 {
   margin-top: 19px;
}

.mr-21 {
   margin-right: 21px;
}

.mt-15 {
   margin-top: 15px;
}

.ml-39 {
   margin-left: 39px;
}

::ng-deep .card-body {
   padding: 16px 16px 16px 25px;
}

.heading {
   color: var(--fiord);
}

.data {
   color: var(--down-river);
   width: fit-content;
}

.mb-1 {
   margin-bottom: 0.5rem;
}

.mb-124 {
   margin-bottom: 124px;
}

::ng-deep {
   @media only screen and (min-width: 320px) and (max-width: 1024px) {
      .table-responsive {
         overflow-x: auto;
      }
   }

   // @media only screen and (min-width: 1025px) and (max-width: 1400px) {
   //     .table-responsive {
   //         overflow-x: hidden;
   //     }
   // }
}

::ng-deep .el-button--success.is-plain {
   color: var(--sea-green);
   background: var(--emerald);
   border-color: var(--emerald);
   padding: 4px 21px 3px 20px;
   opacity: 0.7;
}

fieldset {
   position: relative;
   border: 1px solid var(--ghost);
   border-radius: 3px;
   padding: 0px 10px 10px 10px;
}

fieldset legend {
   color: var(--ghost);
   font-size: 14px;
   font-weight: normal;
   padding-left: 5px;
   padding-right: 5px;
   width: inherit;
   border-bottom: none;
   margin: 0;
   display: block;
   max-width: 100%;
   white-space: normal;
}

::ng-deep html:not([dir="rtl"]) legend {
   float: unset;
}

button {
   opacity: 0.9;
   text-transform: capitalize;

   &[status="pending"],
   &[status="Pending"],
   &[status="partially"],
   &[status="closed"],
   &[status="open"],
   &[status="assigned"],
   &[status="new"],
   &[status="New"],
   &[status="draft"],
   &[status="assign"],
   &[status="close"],
   &[status="Pick & Pack"],
   &[status="picked"],
   &[status="pack"],
   &[status="pick"],
   &[status="created"],
   &[status="queued"] {
      background: var(--light-blue) !important;
      color: var(--dark-blue) !important;
      border: 1px solid var(--light-blue) !important;
   }

   &[status="Future"] {
      background: #EEEEEE !important;
      color: #6B778C !important;
      border: 1px solid #EEEEEE !important;
   }

   &[status="approved"],
   &[status="complete"],
   &[status="completed"],
   &[status="Completed"],
   &[status="in-transit"],
   &[status="confirmed"],
   &[status="loaded"],
   &[status="shipped"],
   &[status="1"],
   &[status="Active"],
   &[status="active"],
   &[status="true"] {
      background: var(--light-green) !important;
      color: var(--dark-green) !important;
      border: 1px solid var(--light-green) !important;
   }

   &[status="in-progress"],
   &[status="In-Progress"],
   &[status="checked-in"],
   &[status="in-fulfillment"] {
      background: #FFF1BF !important;
      color: #EBB90A !important;
      border: 1px solid #FFF1BF !important;
   }

   &[status="rejected"],
   &[status="expired"],
   &[status="cancelled"],
   &[status="partial-fulfilment"],
   &[status="Exception"],
   &[status="exception"] {
      background: var(--light-pink) !important;
      color: var(--dark-pink) !important;
      border: 1px solid var(--light-pink) !important;
   }

   &[status="receipt"],
   &[status="received"],
   &[status="0"],
   &[status="false"],
   &[status="back-order"],
   &[status="in-active"] {
      background: var(--light-purple) !important;
      color: var(--dark-purple) !important;
      border: 1px solid var(--light-purple) !important;
   }

   &[status="void"],
   &[status="unload"],
   &[status="unloaded"] {
      background: var(--light-grey) !important;
      color: var(--dark-grey) !important;
      border: 1px solid var(--light-grey) !important;
   }

   &[status="rfq"] {
      background: var(--light-orange) !important;
      color: var(--dark-orange) !important;
      border: 1px solid var(--light-orange) !important;
      text-transform: uppercase;
   }

   &[status="archive"] {
      background: var(--dark-red) !important;
      color: var(--light-red) !important;
      border: 1px solid var(--dark-red) !important;
      text-transform: capitalize;
    }

   &[history-status="pending"],
   &[history-status="closed"],
   &[history-status="open"],
   &[history-status="assigned"],
   &[history-status="new"],
   &[history-status="draft"],
   &[history-status="assign"],
   &[history-status="close"],
   &[history-status="Pick & Pack"],
   &[history-status="picked"],
   &[history-status="created"],
   &[history-status="queued"] {
      border: 3px solid var(--light-blue) !important;
   }

   &[history-status="approved"],
   &[history-status="complete"],
   &[history-status="completed"],
   &[history-status="in-transit"],
   &[history-status="confirmed"],
   &[history-status="loaded"],
   &[history-status="shipped"],
   &[history-status="1"],
   &[history-status="true"] {
      border: 3px solid var(--light-green) !important;
   }

   &[history-status="in-progress"],
   &[history-status="checked-in"],
   &[history-status="in-fulfillment"] {
      border: 3px solid var(--light-orange) !important;
   }

   &[history-status="rejected"],
   &[history-status="expired"],
   &[history-status="cancelled"] {
      border: 3px solid var(--light-pink) !important;
   }

   &[history-status="receipt"],
   &[history-status="received"],
   &[history-status="0"],
   &[history-status="false"] {
      border: 3px solid var(--light-purple) !important;
   }

   &[history-status="void"],
   &[history-status="unload"],
   &[history-status="unloaded"] {
      border: 3px solid var(--light-grey) !important;
   }

   &[history-status="rfq"] {
      border: 3px solid var(--light-orange) !important;
      text-transform: uppercase;
   }
}

.history-order-select {
   padding: 5px;
   border-radius: 5px;
}

fieldset {
   position: relative;
   border: 1px solid var(--ghost);
   border-radius: 3px;
   padding: 0px 10px 10px 10px;
}

fieldset legend {
   color: var(--ghost);
   font-size: 14px;
   font-weight: normal;
   padding-left: 5px;
   padding-right: 5px;
   width: inherit;
   border-bottom: none;
   margin: 0;
   display: block;
   max-width: 100%;
   white-space: normal;
}