// Variable overrides
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// Color system
:root {
    --primary-color: #153243 !important;
    --primary-color1: #153243 !important;
    --secondary-color: #799496 !important;
    --primary-color-1: #003050cc;
    $custom-primary: #3c4b64;
    --bg-primary: #FAFAFA;
    --custom-primary-1: #00305073;
    --custom-primary-2: #b1b1b14a;
    --blue-primary: #3A82E0;
    --white-primary: white;
    --black-primary: #000000;
    --dim-black: #333333;
    --red-primary: #ff0000;
    --shuttle-gray: #5F6368;
    --shuttle-gray-1: #5a5e66;
    --azure-radiance: #009BFF;
    --catskill-white: #F9FAFC;
    --slate-gray: #6B778C;
    --athens-gray: #FAFBFC;
    --athens-gray-1: #f3f4f6;
    --casper: #aab7cf;
    --persian-blue: #321fdb;
    --silver: #C4C4C4;
    --jordy-blue: #96BFF5;
    --corn: #EBB90A;
    --sunglow: #FFD029;
    --sea-green: #32985C;
    --emerald: #39C673;
    --science-blue: #0052CC;
    --royal-blue: #4154f1;
    --tropical-blue: #dbe8f9;
    --fiord: #333333;
    --selago: #F5F5F5;
    --anakiwa: #B3D4FF;
    --zircon: #dae1ee;
    // --zircon: #f6f9ff;
    --bon-jour: #e4e1e1;
    --pickled-bluewood: #344563;
    --nepal: #899bbd;
    --japanese-laurel: #008000;
    --wild-sand: #F6F6F6;
    --cerulean: #0099E6;
    --mischka: #d8dce5;
    --onahau: #D1E5FF;
    --nile-blue: #172B4D;
    --dodger-blue: #409EFF;
    --porcelain: #F5F6F7;
    --gallery: #EBEBEB;
    --gallery-1: #efefef;
    --oxford-blue: #2D3748;
    --alabaster: #F7F7F7;
    --down-river: #091E42;
    --ghost: #ced4da;
    --cotton-candy: #ffbbd3;
    --dim-pink: rgba(245, 144, 96, 0.2);
    --dim-gray: #F6F5FA;
    --radical-red: #FF1B6D;
    --tuft-bush: #ffd9c5;
    --flush-orange: #FF7F00;
    --cruise: #c4eed5;
    --ocean-green: #47a46d;
    --hawkes-blue: #CADEFC;
    --colonial-white: #EDDFAD;
    // imported colors
    /* Global Styling Variables */
    // Primiary Color Scheme
    --color-global-theme-primary: #74C4E4;
    --color-global-theme-primary-hover: #358ED7;
    --color-global-theme-primary-disabled: #A6A6A6;
    // Secondary Color Scheme
    --color-global-theme-secondary: #74C4e4;
    --color-global-theme-grey: #B0A9A9;
    --color-global-theme-dark: #34495e;
    --color-global-theme-light: #f8f4f4;
    --color-global-theme-error: #f54b5e;
    --color-global-theme-success: #48d2a0;
    --color-global-theme-warning: #f8c51c;
    --color-global-theme-purple: #885af8;
    --color-global-theme-white: #FFFFFF;
    --color-global-theme-row-grey: #FCFAFA;
    --color-global-theme-table-background: #F2F5FA;
    /* Control Styling */
    --color-field-border-color: #ced4da;
    --control-focus-border-color: #55ACEE;
    --control-disbale-border-color: #DDDDDD;
    --control-placeholder-color: #7C8495;
    /* low opacity Variable */
    --color-secondary-low-opacity: #74c4e459;
    /* Grid Text Color */
    --color-grid-text: #373e43;
    --color-global-grid-head: (--color-grid-text, 0.35); //UI Revamp #34495e;
    --color-global-grid-rows: #7a8994; //UI Revamp  #333333
    --color-global-grid-border: rgb(52, 73, 94, 0.2); // Following RGB value of $color-global-theme-dark
    --wms-color-secondary: #74C4e4;
    --light-grey: #9da5b1;


    /**------------------GRID STATUS BUTTONS--------------------**/
    --light-blue: #cedaea;
    --dark-blue: #3a82e0;

    --light-green: #b2dcc3;
    --dark-green: #32985c;

    --light-orange: #eddfad;
    --dark-orange: #ebb90a;

    --light-pink: #eda9c2;
    --dark-pink: #ff1b6d;

    --light-purple: #e4bbe8;
    --dark-purple: #EA0EFF;

    --light-grey: #dcdcdc;
    --dark-grey: #eb778c;

    --light-red: #f2c9c9;
    --dark-red: #e32d2d;
    --status-light-orange:rgba(255, 127, 0, 0.3);
    --light-silver:rgba(196, 196, 196, 0.3) ;
    --red-priority-error: #C60F0F;
}